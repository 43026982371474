import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { usePaidCourses } from 'querys/course'
import { Typography } from 'common/typography'
import Image from 'next/legacy/image'
import { MdClose, MdSearch } from 'react-icons/md'
import { useRouter } from 'next/router'

const CourseSelectionStyled = styled.div`
  position: relative;
  .suggestions-container {
    margin-block-start: 16px;
  }
  .suggestions {
    margin-block-start: 8px;
  }
  .course-selected {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--neutral110);
    border: 1px solid var(--neutral30);
    padding-right: 8px;
    gap: 8px;
    .course-item {
      flex: 1;
    }
    svg {
      cursor: pointer;
    }
  }
  .course-item {
    color: var(--white);
    display: flex;
    align-items: center;
    padding: 8px;
    gap: 8px;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    &.sugested {
      cursor: pointer;
      background: var(--neutral110);
      border: 1px solid var(--neutral30);
      margin-block-end: 8px;
    }
    &:hover {
      background: var(--neutral90);
    }
    &:active {
      background: var(--neutral100);
    }
  }
  .search-list {
    background: var(--neutral80);
    border: 1px solid var(--neutral30);
    border-radius: 4px;
    margin-block-start: 4px;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  .course-selected {
    margin-block-start: 8px;
  }
  .search-input {
    background: var(--neutral100);
    color: var(--white);
    font-family: var(--primaryFont);
    outline: none;
    border: none;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    padding: 12px 8px;
    line-height: 24px;
    width: 100%;
    border: 1px solid var(--neutral40);
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    box-sizing: border-box;
    input {
      width: 100%;
      border: none;
      outline: none;
      color: inherit;
      background: inherit;
      font: var(--regular);
    }
  }
`

function CourseItem({
  title, slug, badge, className, handleClick,
}) {
  return (
    <li className={`${className} course-item`} onClick={handleClick}>
      <Image src={badge} alt={title} width="32" height="32" />
      {title}
    </li>
  )
}

function CourseSelection({ setActivePlan, activePlan }) {
  const { query: { course: coursePath } } = useRouter()
  const [course, setCourse] = useState(null)
  const [value, setValue] = useState('')
  const {
    data, isError,
  } = usePaidCourses()


  const handleSetCourse = (item) => {
    if (!item) return

    setCourse(item)
    setActivePlan({
      ...activePlan,
      course: {
        title: item.title,
        link: `/comprar/cursos/${item.slug}`,
        price: `Pago por un curso $${item.price?.amount} ${item.price?.currency_code}`,
        priceAmount: `$${item.price?.amount} ${item.price?.currency_code}`,
      },
    })
  }

  useEffect(() => {
    if (coursePath && data && setActivePlan) {
      const item = data.find(({ slug }) => slug === coursePath)
      handleSetCourse(item)
    }
  }, [])

  useEffect(() => {
    if (coursePath && data && setActivePlan) {
      const item = data.find(({ slug }) => slug === coursePath)
      handleSetCourse(item)
    }
  }, [data])

  if (isError || !data?.length) return null

  const sugestedCourses = ['javascript', 'javascript-react', 'react']
  const sugested = data.filter(({ slug }) => sugestedCourses.includes(slug))

  const handleChange = (e) => {
    setValue(e.target.value)
  }

  const handleRestore = () => {
    setCourse(null)
    setValue('')
    setActivePlan({
      ...activePlan,
      course: {
        title: 'Aún no has seleccionado un curso',
        price: 'El precio dependerá del curso seleccionado',
        link: null,
        slug: null,
      },
    })
  }
  return (
    <CourseSelectionStyled>
      <label htmlFor="course-item" className="search-input">
        <MdSearch size={24} color="var(--neutral30)" />
        <input placeholder="Buscar curso" value={value} onChange={handleChange} id="course-item" type="text" />
      </label>
      {(value.trim() && !course) ? (
        <ul className="search-list">
          {data.map(
            (item) => (item.title.toLowerCase().includes(value.toLowerCase()) ? (
              <CourseItem key={item.slug} handleClick={() => handleSetCourse(item)} className="searched" {...item} />
            ) : null),
          )
          }
        </ul>
      ) : null}
      {
        course ? (
          <div className="suggestions-container">
            <Typography small="p2Bold" large="regularBold">
              Curso seleccionado
            </Typography>
            <div className="course-selected">
              <a className="course-item" href={`/cursos/${course.slug}`} target="_blank">
                <Image src={course.badge} alt={course.title} width="32" height="32" />
                {course.title}
              </a>
              <MdClose onClick={() => handleRestore()} size={24} color="var(--neutral30)" />
            </div>
          </div>
        ) : null
      }
      {!course ? (
        <div className="suggestions-container">
          <Typography small="p2Bold" large="regularBold">
            Cursos sugeridos
          </Typography>
          <ul className="suggestions">
            {sugested.map((item) => (
              <CourseItem key={item.slug} handleClick={() => handleSetCourse(item)} className="sugested" {...item} />
            ))}
          </ul>
        </div>
      ) : null}
    </CourseSelectionStyled>
  )
}

export default CourseSelection
