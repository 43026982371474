import { useState } from 'react'
import styled from 'styled-components'
import { Typography, DarkGradient } from 'common/typography'
import Wrapper from 'common/wrapper'
import { useSubscriptionPlans } from 'querys/subscriptions'
import { useCountryCode } from 'querys/payment'
import { useTotalUsers } from 'querys/growth'
import PricingDetailDeskop from './pricing-detail-desktop'
import PricingItem from './pricing-item'
import plans from './prices.json'
import PPPBanner from './ppp-banner'

const PricingSectionStyled = styled.div`
  padding-block-start: 80px;
  h1 {
    ${DarkGradient};
    margin-block-end: 32px;
  }
  .desktop-title {
    display: none;
  }
  @media (min-width: 768px) {
    .pricing-section-container {
      position: relative;
    }
    .shadow {
      position: absolute;
      z-index: -1;
      width: 294px;
      height: 322px;
      top: 180px;
      left: 10px;
      border-radius: 50%;
      filter: blur(95px);
      background: linear-gradient(143deg, #00FFF0 0%, rgba(255, 0, 77, 0) 92%);
    }
    .pricing-container {
      display: flex;
      gap: 24px;
    }
    .pricing-plans {
      width: 50%;
      box-sizing: border-box;
    }
    .mobile-title, .desktop-title {
      margin-block-end: 40px;
    }
    .mobile-title {
      display: none;
    }
    .desktop-title {
      display: block;
    }
  }
  @media (min-width: 1024px) {
    padding-block-start: 40px;
  }
`

function PricingSection({ coursePrice, withTitle, withUsersCount }) {
  const { data } = useTotalUsers()
  const { data: { countryCode } } = useCountryCode()
  const [activePlan, setActivePlan] = useState(plans[0])
  const query = useSubscriptionPlans()

  const defaultCoursePrice = {
    price: {
      amount: countryCode === 'MX' ? 1000 : 50,
      currency_code: countryCode === 'MX' ? 'MXN' : 'USD',
    },
    discount: null,
    original_price: null,
  }

  const handleActive = (plan, key) => {
    setActivePlan(plan)
    const detailsList = document.querySelectorAll('details')
    detailsList.forEach((detail) => {
      detail.removeAttribute('open')
    })
  }

  return (
    <PricingSectionStyled aria-label="Sección de precios" id="comprar">
      <Wrapper maxWidth={1200}>
        <div className="pricing-section-container">
          {withUsersCount && data?.count ? (
            <Typography as="h1" small="headline4" large="headline3" align="center">
              Únete a nuestros más de
              {' '}
              {data?.count}
              {' '}
              estudiantes
            </Typography>
          ) : null}
          {withTitle ? (
            <>
              <Typography as="h1" small="headline4" className="mobile-title" align="center">
                Suscribete ahora
              </Typography>
              <Typography as="h1" id="pricing" large="headline3" className="desktop-title" align="center">
                Suscríbete ahora y comienza tu historia en la industria de la tecnología
              </Typography>
            </>
          ) : null}
          <div className="pricing-container">
            <PricingDetailDeskop
              coursePrice={activePlan.id === 'course' ? coursePrice || defaultCoursePrice : null}
              objectKey={activePlan.key}
              query={query}
              setActivePlan={setActivePlan}
              activePlan={activePlan}
              {...activePlan}
            />
            <div className="pricing-plans">
              {plans.map((plan) => (
                <PricingItem
                  plan={plan}
                  key={plan.key}
                  isActive={plan.id === activePlan.id}
                  handleActive={() => handleActive(plan, plan.objectKey)}
                  setActivePlan={setActivePlan}
                  coursePrice={plan.id === 'course' ? coursePrice || defaultCoursePrice : null}
                  objectKey={plan.key}
                  query={query}
                  {...plan}
                  course={activePlan.course}
                />
              ))}
            </div>
          </div>
          <div className="shadow" />
        </div>
      </Wrapper>
      <PPPBanner
        query={query}
        activePlan={activePlan}
        coursePrice={activePlan.id === 'course' ? coursePrice || defaultCoursePrice : null}
      />
    </PricingSectionStyled>
  )
}

export default PricingSection
