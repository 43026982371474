import styled from 'styled-components'

const MSIStyled = styled.button`
  color: var(--white);
  width: fit-content;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('images/icons/msi-arrow.svg');
  top: 0;
  right: 52px;
  block-size: 94px;
  inline-size: 70px;
  cursor: pointer;
  flex-direction: column;
  font: inherit;
  border: none;
  outline: none;
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  span {
    display: block;
    font-weight: 700;
  }
  .msi-number {
    font-size: 24px;
    line-height: 18px;
  }
  .msi-month {
    font-size: 14px;
    line-height: 14px;
  }
  .msi-interest {
    font-size: 10px;
    line-height: 14px;
  }
  @media(max-width: 374px) {
    display: none;
  }
  @media(min-width: 768px) {
    right: 8px;
  }
  @media(min-width: 1366px) {
    right: 8px;
    block-size: 135px;
    inline-size: 100px;
    .msi-number {
      font-size: 32px;
      line-height: 29px;
    }
    .msi-month {
      font-size: 20px;
      line-height: 20px;
    }
    .msi-interest {
      font: var(--smallBold);
    }
  }
`

function MSI() {
  const handleClick = (e) => {
    e.preventDefault()
    window.open('/terminos#msi', '_blank')
  }

  return (
    <MSIStyled className="msi" onClick={handleClick}>
      <span className="msi-number">
        6
      </span>
      <span className="msi-month">
        meses
      </span>
      <span className="msi-interest">
        sin intereses
      </span>
    </MSIStyled>
  )
}

export default MSI
