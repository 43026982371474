import styled from 'styled-components'
import { MdDone, MdClose } from 'react-icons/md'

const AttributesListStyled = styled.ul`
  .attribute {
    display: flex;
    margin: 8px 0;
    align-items: center;
    p {
      margin-left: 8px;
    }
    svg {
      min-width: 30px;
    }
  }
`

function AttributesList({ attributes }) {
  return (
    <AttributesListStyled>
      {attributes.map(({ attribute, isAvailable }) => (
        <li className="attribute" key={attribute}>
          {isAvailable
            ? <MdDone aria-hidden="true" className="close" size={30} color="#4BB543" />
            : <MdClose aria-hidden="true" className="close" size={30} color="#F06D7E" />
          }
          <p>{attribute}</p>
        </li>
      ))}
    </AttributesListStyled>
  )
}

export default AttributesList
