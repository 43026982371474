import styled from 'styled-components'
import Link from 'next/link'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { Typography } from 'common/typography'
import AttributesList from 'components/course/pricing/attributes-list'
import usePlanPrice from 'hooks/use-plan-price'
import DiscountTimer from 'common/banner/discount-timer'
import { useCountryCode } from 'querys/payment'
import MSI from 'common/msi'
import CourseSelection from './course-selection'

const PricingDetailDeskopStyled = styled.div`
  flex: 1;
  width: 50%;
  padding: 24px;
  display: none;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid var(--neutral90);
  position: relative;
  background: #3b3f5680;
  color: var(--white);
  .exclusive-discount {
    margin-block-start: 8px;
    u {
      color: var(--turquoise);
      font: var(--regularBold);
    }
  }
  .plan-title {
    margin-block-end: 4px;
  }
  .plan-description {
    margin-block-end: 24px;
  }
  .attributes-list {
    margin-block-start: 24px;
  }
  .attribute {
    margin-block-end: 16px;
  }
  .coupon-expiration-description {
    color: var(--turquoise);
    font: var(--regularBold);
  }
  .countdow-numbers {
    font: var(--headline5);
  }
  @media (min-width: 768px) {
    display: block;
  }
  @media (min-width: 1030px) {
    /* max-height: 452px; */
    overflow: auto;
  }
`

function PricingDetailDestop({
  id, objectKey, title, price_description, link, attributes, coursePrice, query, setActivePlan, activePlan,
}) {
  const { query: { variant: variant_page } } = useRouter()
  const [experiment, variant_id, country] = variant_page?.split('.') || []
  const { data: { countryCode } } = useCountryCode()
  const { discount } = usePlanPrice({
    id, coursePrice, objectKey, query,
  })

  if (id === 'course') {
    return (
      <PricingDetailDeskopStyled as="div" className="pricing-detail">
        <Typography font="headline4" className="plan-title">{title}</Typography>
        <Typography font="smallRegular" className="plan-description">
          {price_description}
        </Typography>
        {discount ? (
          <div className="timer">
            <DiscountTimer discount={discount} color="inherit" />
          </div>
        ) : null}
        {objectKey === 'course' ? (
          <CourseSelection setActivePlan={setActivePlan} activePlan={activePlan} />
        ) : null}
        <div className="attributes-list">
          <AttributesList attributes={attributes} />
        </div>
      </PricingDetailDeskopStyled>
    )
  }

  // Details of anual, monthly and projects subscription.
  return (
    <PricingDetailDeskopStyled className="pricing-detail">
      <Link href={link} passHref>
        {(objectKey === 'year' && (country === 'mx' || countryCode === 'MX')) ? (
          <MSI />
        ) : null}
        <Typography font="headline4" className="plan-title">{title}</Typography>
        <Typography font="smallRegular" className="plan-description">
          {price_description}
        </Typography>
        {discount ? (
          <div className="timer">
            <DiscountTimer discount={discount} color="inherit" />
          </div>
        ) : null}
        {discount?.coupon_code.includes('unique') ? (
          <div className="exclusive-discount">
            <p>
                El
              {' '}
              <u>60% de descuento</u>
              {' '}
                solo está disponible desde esta página, si sales de ella ya no podrás obtener el descuento.
                ¡Aprovéchalo!
            </p>
          </div>
          ) : null}
        <div className="attributes-list">
          <AttributesList attributes={attributes} />
        </div>
      </Link>
    </PricingDetailDeskopStyled>
  )
}

PricingDetailDestop.defaultProps = {
  coursePrice: null,
}

PricingDetailDestop.propTypes = {
  id: PropTypes.string.isRequired,
  objectKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  price_description: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  attributes: PropTypes.arrayOf(
    PropTypes.shape({
      available: PropTypes.bool,
      description: PropTypes.string,
    }),
  ).isRequired,
  coursePrice: PropTypes.shape({
    price: PropTypes.shape,
    discount: PropTypes.shape,
    original_price: PropTypes.shape,
  }),
}

export default PricingDetailDestop
