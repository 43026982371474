import { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { flag } from 'country-emoji'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'
import { useCountryCode } from 'querys/payment'
import { useRouter } from 'next/router'

const ActivatePPPStyled = styled.label`
  user-select: none;
  display: block;
  cursor: pointer;
  .ppp {
    font: var(--regularBold);
  }
  .ppp-check {
    margin-block-start: 10px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  input {
    display: none;
  }
`

function ActivatePPP({ setCoupon, id }) {
  // const { data: pppData } = useSubscriptionPlans('ppp')
  // const { data: normalData } = useSubscriptionPlans()
  const { query: { coupon_code } } = useRouter()
  const { data: { country, countryCode } } = useCountryCode()
  const [check, setCheck] = useState(true)

  if (coupon_code && coupon_code.includes('unique')) return null

  const handleChange = () => {
    if (check) {
      setCoupon('')
    } else {
      const existentCoupon = sessionStorage.getItem('initalCoupon')
      if (existentCoupon) {
        setCoupon(existentCoupon)
      } else {
        setCoupon('ppp')
      }
    }
    setCheck(!check)
  }

  return (
    <ActivatePPPStyled htmlFor={id} className="ppp-container">
      <input id={id} type="checkbox" onChange={() => handleChange()} />
      <p className="ppp">
        Notamos que eres de
        {' '}
        {country}
        {'. '}
        Así que te ofreceremos un precio especial para ayudarte a iniciar en tu carrera profesional.
      </p>
      <div className="ppp-check">
        {
          check ? (
            <MdCheckBox size={24} color="var(--lightBlue)" />
          ) : (
            <MdCheckBoxOutlineBlank size={24} color="var(--white)" />
          )
        }
        <p>
          Activar descuento para
          {' '}
          {country}
          {' '}
          {flag(countryCode)}
        </p>
      </div>
    </ActivatePPPStyled>
  )
}

ActivatePPP.defaultProps = {
  id: 'ppp',
}

ActivatePPP.propTypes = {
  setCoupon: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default ActivatePPP
