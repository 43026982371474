import styled from 'styled-components'
import Link from 'next/link'
import ActivatePPP from 'course/pricing/activate-ppp'
import { SecondaryButton } from 'common/button'
import { Typography } from 'common/typography'
import { useRouter } from 'next/router'
import Wrapper from 'common/wrapper'
import CourseBanner from './course-banner'

const PppBannerStyled = styled.div`
  display: none;
  padding: 16px 0;
  z-index: 3;
  position: static;
  ${({ isFixed }) => (isFixed ? `
    position: fixed;
  ` : '')}
  bottom: 0;
  right: 0;
  left: 0;
  color: var(--baseNeutral);
  backdrop-filter: blur(7px);
  background: rgb(4 0 30 / 75%);
  .banner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
  }
  .ppp-container {
    max-width: 486px;
    .ppp {
      font: var(--smallBold);
    }
    .ppp-check {
      font: var(--smallBold);
    }
  }
  .plan-details {
    display: flex;
    align-items: center;
    gap: 48px;
    ${({ withoutDiscount }) => (withoutDiscount ? `
      flex: 1;
      justify-content: space-between;
    ` : null)}
  }
  @media (min-width: 768px) {
    display: block;
  }
`

function PPPBanner({ query, activePlan, coursePrice }) {
  const { query: { coupon_code }, pathname } = useRouter()
  const {
    key, title, price_description, cta, link, course,
  } = activePlan
  const { data } = query
  const { discount, price } = (data && data[key]) || coursePrice || {}

  const withDiscount = discount?.status === 'VALID' && !(coupon_code?.includes('unique'))
  const pppActive = (key === 'shared' || key === 'year') && withDiscount
  const isFixed = pathname.includes('precios') || pathname.includes('blackfriday')

  if (key === 'course') {
    return (
      <PppBannerStyled className="ppp-banner" isFixed={isFixed}>
        <Wrapper>
          <CourseBanner course={course} />
        </Wrapper>
      </PppBannerStyled>
    )
  }

  return (
    <PppBannerStyled withoutDiscount={!withDiscount} isFixed={isFixed} className="ppp-banner">
      <Wrapper>
        <div className="banner-container">
          {key === 'year' ? (
            <ActivatePPP id="banner" setCoupon={query.setCoupon} />
          ) : null}
          <div className="plan-details">
            <div>
              <Typography font="regularBold">{title}</Typography>
              <Typography font="smallRegular">
                {price_description}
                {' '}
                <b>
                  $
                  {price?.amount}
                  {' '}
                  {price?.currency_code}
                </b>
              </Typography>
            </div>
            <Link href={link} passHref>
              <SecondaryButton>
                {cta}
              </SecondaryButton>
            </Link>
          </div>
        </div>
      </Wrapper>
    </PppBannerStyled>
  )
}

export default PPPBanner
