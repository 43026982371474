function usePlanPrice({
  id, coursePrice, objectKey, query,
}) {
  const { data, setCoupon } = query

  if (id === 'course') {
    return coursePrice
  }

  const placeHolder = {
    price: {
      amount: 0,
      currency_code: 'USD',
    },
    discount: null,
    original_price: null,
  }

  const plan = (data && data[objectKey]) || placeHolder

  if (plan) {
    const { price, original_price, discount } = plan
    return {
      price,
      discount: discount?.status === 'VALID' ? discount : null,
      original_price: discount?.status === 'VALID' ? original_price : null,
      setCoupon,
    }
  }

  return placeHolder
}

export default usePlanPrice
