import { memo } from 'react'
import Wrapper from 'common/wrapper'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Link from 'next/link'
import Image from 'next/legacy/image'
import pushToDataLayer from 'utils/push-to-datalayer'

const FooterStyled = styled.footer`
  background-color: ${({ background }) => background || 'var(--neutral100)'};
  color: white;
  padding: 2em 0;
  a {
    color: var(--white);
    &:hover {
      text-decoration: underline;
    }
  }
  span {
    text-decoration: underline;
    cursor: pointer;
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(5,minmax(186px, 1fr));
    grid-gap: 32px;
  }
  .logo {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    img {
      width: 50px;
    }
    p {
      color: black;
    }
  }
  small {
    color: #aeb7c1;
    font-size: 12px;
  }
  .social_icons {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    max-width: 104px;
  }
  .social_icons a {
    cursor: pointer;
    height: 18px;
  }
  .social_title {
    padding-bottom: 8px;
    font-size: 21px;
    line-height: 26px;
    margin: 0 0 16px 0;
    border-bottom: 0.5px solid #858789;
    color: var(--neutral70);
  }
  .grid_content {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 24px;
  }
  @media screen and (max-width: 1125px) {
    .grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media screen and (max-width: 768px) {
    .grid {
      grid-template-columns: 1fr;
    }
  }
`

function Footer({ background }) {
  return (
    <FooterStyled background={background}>
      <Wrapper>
        <div className="grid">
          <div>
            <div className="logo">
              <Link id="f-home" href="/" passHref>
                <Image
                  src="/icons/icon-50x50.png"
                  alt="Logo de leoniasesteban.com"
                  width="40"
                  height="40"
                />
              </Link>
            </div>
            <small>© Leonidas Esteban 2021</small>
            <div className="social_icons">
              <a target="_blank" rel="noopener noreferrer" aria-label="Instagram" href="https://www.instagram.com/leonidasesteban/" id="f-instagram">
                <Image
                  src="/images/footer/instagram.svg"
                  alt="instagram"
                  width="18"
                  height="18"
                />
              </a>
              <a target="_blank" rel="noopener noreferrer" aria-label="Facebook" href="https://www.facebook.com/LeonidasEsteban/" id="f-facebook">
                <Image
                  src="/images/footer/facebook.svg"
                  alt="facebook"
                  width="18"
                  height="18"
                />
              </a>
              <a target="_blank" rel="noopener noreferrer" aria-label="Twitter" href="https://twitter.com/leonidasesteban" id="f-twitter">
                <Image
                  src="/images/footer/twitter.svg"
                  alt="twitter"
                  width="18"
                  height="18"
                />
              </a>
            </div>
          </div>
          <div>
            <p className="social_title">Contenido</p>
            <div className="grid_content">
              <Link id="f-becas" href="/blog/becas-por-estudiar">
                Becas
              </Link>
              <Link href="/blog">
                Blog
              </Link>
              <Link href="/pokedex">
                Pokédex
              </Link>
              <a href="https://www.patreon.com/LeonidasEsteban" id="f-patreon" target="_blank" rel="noopener noreferrer">
                Mentoría
              </a>
              <a href="https://www.youtube.com/leonidasesteban" id="f-youtube" target="_blank" rel="noopener noreferrer">
                Youtube
              </a>
            </div>
          </div>
          <div>
            <p className="social_title">Cursos</p>
            <div className="grid_content">
              <Link
                href="/cursos"
                id="h-courses"
                onClick={() => pushToDataLayer({
                  event: 'linkCourses',
                  eventCategory: 'account',
                  eventAction: 'linkInformation',
                })}
              >
                Todos los cursos
              </Link>
              <Link href="/cursos/javascript-react" id="f-cursoJsXReact">
                Curso de JavaScript Avanzado
              </Link>
              <Link href="/cursos/html-css-fundamentos" id="f-cursoHtmlCssFundamentos">
                Curso esencial de HTML y CSS
              </Link>
              <Link href="/cursos/flexbox-componentes" id="f-cursoFlexboxComponensts">
                Curso de Flexbox Layout y Componentes
              </Link>
            </div>
          </div>
          <div>
            <p className="social_title">Sobre mí</p>
            <div className="grid_content">
              <a href="https://www.youtube.com/watch?v=5f5Ig_U2Bpk" target="_blank" rel="noopener noreferrer" id="f-noTeRindas">¿Quién soy?</a>
              <a href="https://www.youtube.com/watch?v=cuyYAYFI414" target="_blank" rel="noopener noreferrer" id="f-why">¿Por qué?</a>
              {/* <p>Text</p>
              <p>Text</p>
              <p>Text</p> */}
            </div>
          </div>
          <div>
            <p className="social_title">Legal</p>
            <div className="grid_content">
              <a href="mailto:me@leonidasesteban.com" id="f-contact">Contácto</a>
              <Link href="/privacidad" id="f-privacy">
                Política de privacidad
              </Link>
              <Link href="/terminos" id="f-condicitions">
                Términos y condiciones de servicio
              </Link>
            </div>
          </div>
        </div>
      </Wrapper>
    </FooterStyled>
  )
}

Footer.defaultProps = {
  background: 'var(--darkBlue)',
}

Footer.propTypes = {
  background: PropTypes.string,
}

const FooterMemo = memo(Footer)

export default FooterMemo
