/* eslint-disable no-nested-ternary */
import styled from 'styled-components'
import { FaChevronDown } from 'react-icons/fa'
import PropTypes from 'prop-types'
import { Typography } from 'common/typography'
import { SecondaryButton } from 'common/button'
import Link from 'next/link'
import AttributesList from 'components/course/pricing/attributes-list'
import { MdOutlineRadioButtonChecked, MdOutlineRadioButtonUnchecked } from 'react-icons/md'
import DiscountTimer from 'common/banner/discount-timer'
import usePlanPrice from 'hooks/use-plan-price'
import ActivatePPP from 'course/pricing/activate-ppp'
import { useCountryCode } from 'querys/payment'
import MSI from 'common/msi'
import CourseSelection from './course-selection'
import CourseSelectionPrice from './course-selection-price'

const PricingItemStyled = styled.details`
  position: relative;
  border-radius: 4px;
  margin-block-end: 16px;
  border: 1px solid var(--neutral90);
  background: transparent;
  &.active {
    background: #3b3f5680;
  }
  &[open] {
    &::before {
      position: absolute;
      content: "";
      width: 45vw;
      height: 229px;
      top: 10%;
      left: 10px;
      background: linear-gradient(144deg, #00FFF0 8%, rgba(255, 0, 77, 0) 92%);
      filter: blur(80px);
      z-index: -1;
    }
    summary svg {
      transform: rotate(180deg);
    }
  }
  &:last-of-type {
    margin-block-end: 0;
  }
  .plan-summary {
    padding: 16px;
    cursor: pointer;
    user-select: none;
    list-style: none;
    .plan-title-container svg {
      position: absolute;
      top: 16px;
      right: 16px;
    }
  }
  u {
    font: var(--regularBold);
    color: var(--turquoise);
  }
  summary::marker, summary::-webkit-details-marker {
    display: none;
    color: transparent;
  }
  .price-description-lg {
    display: none;
  }
  .price-container {
    margin-block-start: 16px;
  }
  .price-description-sm {
    margin-block-start: 4px;
  }
  .plan-detail {
    padding: 16px;
    padding-block-start: 0;
  }
  .plan-cta {
    margin-block-start: 16px;
  }
  .recomended {
    display: block;
    width: fit-content;
    font-size: 10px;
    padding: 2px 4px;
    font-weight: 700;
    line-height: 12px;
    border-radius: 4px;
    background: #FFDD66;
    margin-block-end: 4px;
    color: var(--neutral110);
  }
  .plan-checkbox {
    display: none;
    color: var(--turquoise);
  }
  .original-price {
    text-decoration: line-through;
    color: #F06D7E;
  }
  .timer {
    margin-block-end: 24px;
  }
  .coupon-expiration-description {
    color: var(--turquoise);
    font: var(--p2Bold);
  }
  .countdow-numbers {
    font: var(--p2Bold);
  }
  .ppp-container {
    margin-block-start: 24px;
    margin-block-end: 16px;
  }
  .course-selection-container {
    margin-block-end: 24px;
  }
  .yearly-price {
    font: var(--smallRegular);
    color: var(--neutral30);
  }
  .per-month {
    font: var(--p2Regular);
    color: var(--neutral30);
  }
  @media (min-width: 768px) {
    &[open] {
      &::before {
        display: none;
      }
    }
    .plan-summary {
      padding: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      .plan-title-container svg {
        display: none;
      }
    }
    .price-container {
      min-width: 150px;
      text-align: right;
      margin: 0;
    }
    .plan-checkbox {
      display: block;
    }
    .pricing-item-container {
      display: flex;
      align-items: center;
      gap: 16px;
    }
    .plan-detail {
      display: none;
    }
    .price-description-sm {
      display: none;
    }
    .course-selection-container {
      display: none;
    }
    .price-description-lg {
      display: block;
    }
    .msi {
      display: none;
    }
  }
`

function PricingItem({
  id, objectKey, title, price_description, cta, link,
  isActive, handleActive, attributes, coursePrice, query, plan, setActivePlan, course,
}) {
  const { data: { countryCode } } = useCountryCode()
  const {
    price: { amount, currency_code }, discount, original_price,
  } = usePlanPrice({
    id, coursePrice, objectKey, query,
  })
  const ctaLink = objectKey === 'course' ? course?.link : link
  const disabled = objectKey === 'course' && !course?.link

  return (
    <PricingItemStyled id={objectKey} className={`pricing-item ${isActive && 'active'}`} open={objectKey === 'year'}>
      <summary className="plan-summary" onClick={() => handleActive()}>
        <div className="pricing-item-container">
          <div className="plan-checkbox">
            {isActive ? (
              <MdOutlineRadioButtonChecked size="24" color="inherit" />
            ) : (
              <MdOutlineRadioButtonUnchecked size="24" color="inherit" />
            )}
          </div>
          <div className="plan-title-container">
            {objectKey === 'year' ? <span className="recomended">Más popular</span> : null}
            <Typography className="" small="headline6" large="regularBold">
              {title}
            </Typography>
            <Typography className="price-description-lg" font="smallRegular">
              {price_description}
            </Typography>
            <FaChevronDown size="20" color="var(--turquoise)" />
          </div>
        </div>
        <div className="price-container">
          {original_price ? (
            <Typography font="smallRegular" className="original-price">
              $
              {objectKey === 'year' ? original_price.amount / 12 : original_price.amount}
              {' '}
              {currency_code}
            </Typography>
          ) : null}
          <div>
            <Typography font="headline5" className="price">
              {objectKey === 'course' ? <CourseSelectionPrice course={course} /> : null}
              {objectKey !== 'course' ? (
                objectKey === 'year' ? (
                  <>
                    {`$${Math.ceil(amount / 12)} ${currency_code}`}
                    <span className="per-month">
                      /mes
                    </span>
                  </>
                ) : (
                  `$${amount} ${currency_code}`
                )
              ) : null}
            </Typography>
            <Typography font="smallRegular" className="price-description-sm">
              {price_description}
            </Typography>
            {(objectKey === 'year') ? (
              <span className="yearly-price">
                <p>
                  Pagando anualmente
                  {' '}
                  {amount}
                  {' '}
                  {countryCode === 'MX' ? 'a 6 meses sin intereses' : null}
                </p>
              </span>
            ) : null}
          </div>
        </div>
        {(objectKey === 'year' && countryCode === 'MX') ? (
          <MSI />
        ) : null}
      </summary>
      <div className="plan-detail">
        {objectKey === 'course' ? (
          <div className="course-selection-container">
            <CourseSelection setActivePlan={setActivePlan} activePlan={plan} />
          </div>
        ) : null}
        {discount?.coupon_code.includes('unique') ? (
          <div className="exclusive-discount">
            <p>
              El
              {' '}
              <u>60% de descuento</u>
              {' '}
              solo está disponible desde esta página, si sales de ella ya no podrás obtener el descuento.
              ¡Aprovéchalo!
            </p>
          </div>
        ) : null}
        {discount ? (
          <div className="timer">
            <DiscountTimer discount={discount} color="var(--white)" />
          </div>
        ) : null}
        <AttributesList attributes={attributes} />
        {objectKey === 'shared' || objectKey === 'year' ? (
          <ActivatePPP id={id} setCoupon={query.setCoupon} />
        ) : null}
        {disabled ? (
          <SecondaryButton className="plan-cta" fullWidth disabled>
            No hay ningun curso seleccionado
          </SecondaryButton>
        ) : (
          <Link href={ctaLink} passHref>
            <SecondaryButton className="plan-cta" fullWidth>
              {cta}
            </SecondaryButton>
          </Link>
        )}
      </div>
    </PricingItemStyled>
  )
}

PricingItem.defaultProps = {
  coursePrice: null,
  objectKey: null,
}

PricingItem.propTypes = {
  id: PropTypes.string.isRequired,
  objectKey: PropTypes.string,
  title: PropTypes.string.isRequired,
  price_description: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  handleActive: PropTypes.func.isRequired,
  attributes: PropTypes.arrayOf(
    PropTypes.shape({
      available: PropTypes.bool,
      description: PropTypes.string,
    }),
  ).isRequired,
  coursePrice: PropTypes.shape({
    price: PropTypes.shape({
      amount: PropTypes.number,
      currency_code: PropTypes.string,
    }),
    discount: PropTypes.object,
    original_price: PropTypes.object,
  }),
}

export default PricingItem
