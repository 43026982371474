import styled from 'styled-components'
import Link from 'next/link'
import { SecondaryButton } from 'common/button'
import { Typography } from 'common/typography'
import { useCourseWithSavedCoupon } from 'querys/course'

const CourseBannerStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 48px;
  justify-content: space-between;
  flex: 1;
`

function CourseBanner({ course }) {
  const { data } = useCourseWithSavedCoupon(course.slug)

  return (
    <CourseBannerStyled>
      <div>
        <Typography font="regularBold">{course.title}</Typography>
        {!data?.price ? (
          <Typography font="smallRegular">
            {course.price}
          </Typography>
        ) : (
          <Typography font="smallRegular">
            Pago por este curso:
            $
            {data.price.amount}
            {' '}
            {data.price.currency_code}
          </Typography>
        )}
      </div>
      {course.link ? (
        <Link href={course.link} passHref>
          <SecondaryButton>
            Comprar curso
          </SecondaryButton>
        </Link>
      ) : (
        <SecondaryButton disabled>
          Comprar curso
        </SecondaryButton>
      )}
    </CourseBannerStyled>
  )
}

export default CourseBanner
