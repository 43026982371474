import { useCountryCode } from 'querys/payment'
import { useCourseWithSavedCoupon } from 'querys/course'

function CourseSelectionPrice({ course }) {
  const { data: { countryCode } } = useCountryCode()
  const { data } = useCourseWithSavedCoupon(course?.slug)

  if (!course?.priceAmount && !data?.price) {
    return (
      countryCode === 'MX' ? '$400 - $1000 MXN' : '$20 - $50 USD'
    )
  }

  if (course?.priceAmount && !data?.price) {
    return course?.priceAmount
  }

  if (data?.price) {
    return (
      <>
        $
        {data?.price.amount}
        {' '}
        {data?.price.currency_code}
      </>
    )
  }

  return (
    countryCode === 'MX' ? '$400 - $1000 MXN' : '$20 - $50 USD'
  )
}

export default CourseSelectionPrice
